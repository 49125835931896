import React, { useState } from "react";
import "./services.css";

const Services = () => {
	const [toggleState, setToggleState] = useState(0);

	const toggleTab = (index) => {
		setToggleState(index);
	};

	return (
		<section className='services section' id='services'>
			<h2 className='section__title'>Services</h2>
			<span className='section__subtitle'>What we offer</span>

			<div className='services__container container grid'>
				<div className='services__content'>
					<div>
						<i className='uil uil-web-grid services__icon'></i>
						<h3 className='services__title'>
							Audit & <br /> Assurance Services
						</h3>
					</div>

					<span
						className='services__button'
						onClick={() => toggleTab(1)}>
						View More
						<i className='uil uil-arrow-right services__button-icon'></i>
					</span>

					<div
						className={
							toggleState === 1
								? "services__modal active-modal"
								: "services__modal"
						}>
						<div className='services__modal-content'>
							<i
								onClick={() => toggleTab(0)}
								className='uil uil-times services__modal-close'></i>

							<h3 className='services__modal-title'>
								Audit & Assurance Services
							</h3>
							{/* <p className='services__modal-description'>
								Service with more than 3 years of
								experience.Providing quality work to clients and
								companies.
							</p> */}

							<ul className='services__modal-services grid'>
								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Statutory Audit.
									</p>
								</li>

								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Tax Audit.
									</p>
								</li>

								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Regulatory & Compliance Audit.
									</p>
								</li>

								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Certification Services.
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className='services__content'>
					<div>
						<i className='uil uil-arrow services__icon'></i>
						<h3 className='services__title'>
							Management <br /> Advisory
						</h3>
					</div>

					<span
						className='services__button'
						onClick={() => toggleTab(2)}>
						View More
						<i className='uil uil-arrow-right services__button-icon'></i>
					</span>

					<div
						className={
							toggleState === 2
								? "services__modal active-modal"
								: "services__modal"
						}>
						<div className='services__modal-content'>
							<i
								onClick={() => toggleTab(0)}
								className='uil uil-times services__modal-close'></i>

							<h3 className='services__modal-title'>
								Management Advisory
							</h3>
							{/* <p className='services__modal-description'>
								Service with more than 3 years of
								experience.Providing quality work to clients and
								companies.
							</p> */}

							<ul className='services__modal-services grid'>
								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Risk Based Internal Audit.
									</p>
								</li>

								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Concurrent Audit.
									</p>
								</li>

								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Management Audit.
									</p>
								</li>

								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Stock Audit.
									</p>
								</li>

								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										ICFR Implementation.
									</p>
								</li>
								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Process Validation.
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className='services__content'>
					<div>
						<i className='uil uil-edit services__icon'></i>
						<h3 className='services__title'>
							Tax <br /> Advisory
						</h3>
					</div>

					<span
						className='services__button'
						onClick={() => toggleTab(3)}>
						View More
						<i className='uil uil-arrow-right services__button-icon'></i>
					</span>

					<div
						className={
							toggleState === 3
								? "services__modal active-modal"
								: "services__modal"
						}>
						<div className='services__modal-content'>
							<i
								onClick={() => toggleTab(0)}
								className='uil uil-times services__modal-close'></i>

							<h3 className='services__modal-title'>
								Tax Advisory
							</h3>
							{/* <p className='services__modal-description'>
								Service with more than 3 years of
								experience.Providing quality work to clients and
								companies.
							</p> */}

							<ul className='services__modal-services grid'>
								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Domestic Tax.
									</p>
								</li>

								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										International Tax.
									</p>
								</li>

								<li className='services__modal-service'>
									<i className='uil uil-check-circle services__modal-icon'></i>
									<p className='services__modal-info'>
										Goods & Service Tax.
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Services;
